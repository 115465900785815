import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/upload/:codigo',
    name: 'upload',
    component: () => import(/* webpackChunkName: "upload" */ '../views/UploadView.vue')
  },
  {
    path: '/album/:codigo',
    name: 'album',
    component: () => import(/* webpackChunkName: "album" */ '../views/AlbumView.vue')
  },
  {
    path: '/incorrecto',
    name: 'incorrecto',
    component: () => import(/* webpackChunkName: "incorrecto" */ '../views/IncorrectoView.vue')
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import(/* webpackChunkName: "Error404" */ '../views/Error404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
