<template>
  <router-view/>
</template>

<style>
#app{
  height: 100vh;
  text-align: center;
}
</style>
