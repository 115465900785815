<template>
  <div class="row rowcenter">
    <div>
      <h1>Captura la magia de los momentos especiales.</h1>
      <p>Creamos un album digital en colaboración de tus seres queridos.</p>
    </div>
  </div>
</template>

<style scoped>
.rowcenter {
  height: 100vh;
  align-items: center;
}
.row {
    --bs-gutter-x: 0rem !important; 
}
</style>